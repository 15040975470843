<template>
  <div class="nav" v-show="drawer">
    <!-- 遮罩层 -->
    <div class="mask" @click="close" @touchmove.stop.prevent></div>
    <!-- 内容 -->
    <div class="warpper" :style="{ width: drawer ? '240px' : '0px' }">
      <!-- 博主介绍 -->
      <!-- <div class="blogger-info">
        <v-avatar size="110" style="margin-bottom: 0.5rem">
          <img :src="$store.state.webSiteInfo.authorAvatar" alt="" />
        </v-avatar>
      </div> -->
      <!-- 博客信息 -->
      <div class="blog-info-wrapper">
        <div class="blog-info-data">
           <router-link :to="{ path: '/archive' }"> 
            <div style="font-size: 0.875rem">文章</div>
            <div style="font-size: 1.125rem">
              {{ $store.state.siteCount.articleCount }}
            </div>
      </router-link>
        </div>
        <div class="blog-info-data">
           <router-link :to="{ path: '/categorys' }"> 
            <div style="font-size: 0.875rem">分类</div>
            <div style="font-size: 1.125rem">
              {{ $store.state.siteCount.categoryCount }}
            </div>
            </router-link>
        </div>
        <div class="blog-info-data">
              <router-link :to="{ path: '/tag' }"> 
            <div style="font-size: 0.875rem">标签</div>
            <div style="font-size: 1.125rem">
              {{ $store.state.siteCount.tagCount }}
            </div>
              </router-link>
        </div>
      </div>
      <hr />
      <!-- 页面导航 -->
      <div class="menu-container">
        <div class="menus-item">
          <a href="/"> <i class="iconfont icon-home" /> 首页 </a>
        </div>
 <div class="menus-item">
          <router-link :to="{ path: '/case' }"> 武器箱&大行动 </router-link>
        </div>
         <div class="menus-item">
           <!-- <router-link :to="{ path: '/standings' }" :class="'item'">
          V社战队排名
        </router-link> -->
        </div>
          <div class="menus-item">
 <router-link :to="{ path: '/navigationPage' }" :class="'item'"> CS2导航大全     </router-link>
                        </div>
         
        <!-- <div class="menus-item"> -->
          <!-- <router-link
            :to="{ path: '/category', query: { id: 19, name: '新闻资讯' } }"
            :class="path == '/links' ? 'active' : 'item'"
          >
            新闻资讯
          </router-link> -->
        <!-- </div> -->
        <div class="menus-item">
          <router-link
            :to="{ path: '/category', query: { id: 12, name: '教程攻略' } }"
            :class="path == '/links' ? 'active' : 'item'"
          >
            教程攻略
          </router-link>
        </div>
        <div class="menus-item">
          <router-link
            :to="{ path: '/updatenotes' }"
            :class="path == '/links' ? 'active' : 'item'"
          >
            更新日志
          </router-link>
        </div>
        <div class="menus-item">
      <router-link
          :to="{ path: '/workshop'}"
          :class="'item2'"
        >
        
           创意工坊
        </router-link>
        </div>
        <div class="menus-item">
          <router-link
            :to="{ path: '/command'}"
            :class="path == '/links' ? 'active' : 'item'"
          >
           指令大全
          </router-link>
        </div>
        <div class="menus-item">
          <router-link to="/archive"> 文章归档 </router-link>
        </div>
        <div class="menus-item">
          <router-link :to="{ path: '/categorys' }"> 文章分类 </router-link>
        </div>
        <div class="menus-item">
          <router-link :to="{ path: '/tag' }"> 标签 </router-link>
        </div>

        <div class="menus-item">
          <router-link :to="{ path: '/about' }"> 关于 </router-link>
        </div>
       
      </div>
    </div>
  </div>
</template>

<script>
import { logout } from "@/api";
import { getUser, removeUser } from "@/utils/auth";
export default {
  data: function () {
    return {
      img: process.env.VUE_APP_IMG_API,
      path: "",
    };
  },
  computed: {
    drawer: {
      set(value) {
        this.$store.state.drawer = value;
      },
      get() {
        return this.$store.state.drawer;
      },
    },
    isLogin() {
      let user = getUser();
      return user != null;
    },
  },

  methods: {
       handleClike(val) {
      this.$router.push({
        path: val,
        hash: window.location.hash.substr(1),
      });
    },
    close() {
      this.$store.state.drawer = false;
    },
    openLogin() {
      this.$store.state.loginFlag = true;
    },
    logout() {
      //如果在个人中心则跳回上一页
      if (this.$route.path === "/user") {
        this.$router.go(-1);
      }
      logout()
        .then((res) => {
          removeUser();
          location.reload();
          this.$toast({ type: "success", message: "注销成功" });
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
a {
  color: var(--text-color);
}

.nav {
  height: 100%;

  .mask {
    background: rgba(101, 101, 101, 0.8); //设置透明度 ，改最后一个数值，0-1
    width: 100%;
    height: 100%;
    z-index: 9;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .warpper {
    font-weight: 700;
    font-size: 1.2rem;
    background-color: var(--background-color);
    position: fixed;
    top: 0;
    z-index: 10;
    height: 100vh;
    animation: 0.8s ease 0s 1 normal none running sidebarItem;

    .blogger-info {
      padding: 26px 30px 0;
      text-align: center;
      z-index: 99;
    }

    .blog-info-wrapper {
      display: flex;
      align-items: center;
      padding: 12px 10px 0;

      .blog-info-data {
        flex: 1;
        line-height: 2;
        text-align: center;

        a {
          text-decoration: none;
        }
      }
    }

    hr {
      border: 2px dashed #d2ebfd;
      margin: 20px 0;
    }

    .menu-container {
      padding: 0 10px 40px;
      overflow: scroll;

      .menus-item {
        a {
          padding: 6px 30px;
          display: block;
          line-height: 2;
          text-decoration: none;
        }

        i {
          margin-right: 1rem;
        }
      }
    }
  }
}

@keyframes sidebarItem {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(0);
  }
}
</style>
