import { setSkin } from "@/utils/skin";
import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);
const routes = [
    {
        path: "/",
        component: resolve => require(["@/view/home/index.vue"], resolve),
        meta: {
            title: "CS2中文网"
        }
    },

    {
        path: '/panel1',
        component:  resolve => require(["@/view/workshop/workshop.vue"], resolve),
        name: 'Panel1',
        meta: { tabName: 'panel1' }
      },
      {
        path: '/updatenotes',
        component:  resolve => require(["@/view/updatenotes/index.vue"], resolve),
        name: 'updatenotes',
        meta: { tabName: 'updatenotes' }
      },
      {
        path: '/command',
        component:  resolve => require(["@/view/command/index.vue"], resolve),
        name: 'command',
        meta: { tabName: 'command' }
      },


    {

        path: "/workshop",
        component: resolve => require(["@/view/workshop/index.vue"], resolve),
        meta: {
            title: "创意工坊"
        }
    },
    {

        path: "/links",
        component: resolve => require(["@/view/link/index.vue"], resolve),
        meta: {
            title: "友情链接"
        }
    },
    {
        path: "/articleInfo",
        component: resolve => require(["@/view/article/index.vue"], resolve),
        meta: {
            title: "文章详情"
        }
    },
    {
        path: "/message",
        component: resolve => require(["@/view/message/index.vue"], resolve),
        meta: {
            title: "留言板"
        }
    },
    {
        path: "/about",
        component: resolve => require(["@/view/about/index.vue"], resolve),
        meta: {
            title: "关于作者"
        }
    },
    {
        path: "/news",
        component: resolve => require(["@/view/news/index.vue"], resolve),
        meta: {
            title: "更新日志"
        }
    },
    {
        path: "/search",
        component: resolve => require(["@/view/search/index"], resolve),
        meta: {
            title: "搜索文章"
        }
    },
    {
        path: "/archive",
        component: resolve => require(["@/view/archive/index"], resolve),
        meta: {
            title: "文章归档"
        }
    },
    {
        path: "/categorys",
        component: resolve => require(["@/view/category/Category"], resolve),
        meta: {
            title: "文章分类"
        }
    },
    {
        name: "/category",
        path: "/category",
        component: resolve => require(["@/view/category/index"], resolve),
    },
    {
        path: "/tag",
        component: resolve => require(["@/view/tag/Tag"], resolve),
    },
    {
        path: "/tags",
        name: "/tags",
        component: resolve => require(["@/view/tag/index"], resolve),
    },
    {
        path: "/photo",
        component: resolve => require(["@/view/photo/index"], resolve),
    },
    {
        path: "/sponsor",
        component: resolve => require(["@/view/sponsor/index"], resolve),
    },
    {
        path: "/user",
        component: resolve => require(["@/view/user/index"], resolve),
    },
    {
        path: "/im",
        component: resolve => require(["@/view/im/index"], resolve),
    },
    {
        path: "/case",
        component: resolve => require(["@/view/case/index"], resolve),
    },
    {
        path: "/standings",
        component: resolve => require(["@/view/standings/index"], resolve),
    },
    {
        path: "/callback/qq",
        component: resolve => require(["@/components/model/OauthLogin.vue"], resolve)
    },
    {
        path: "/callback/gitee",
        component: resolve => require(["@/components/model/OauthLogin.vue"], resolve)
    },
    {
        path: "/callback/weibo",
        component: resolve => require(["@/components/model/OauthLogin.vue"], resolve)
    },
    {
        path: "/navigationPage",
        component: resolve => require(["@/view/navigationPage/index"], resolve),
    },
    {
        path: "*",
        name: "NotFound",
        component: () => import("@/view/404/404.vue"),
    }
];

const router = new VueRouter({
    // mode: "history",
    mode: "hash",
    scrollBehavior: () => ({ y: 0 }),
    routes
});
// 获取原型对象push函数
const originalPush = VueRouter.prototype.push

// 获取原型对象replace函数
const originalReplace = VueRouter.prototype.replace

// 修改原型对象中的push函数
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

// 修改原型对象中的replace函数
VueRouter.prototype.replace = function replace(location) {
    return originalReplace.call(this, location).catch(err => err)
}

router.beforeEach((to, from, next) => {
    setSkin()

    next()
})
export default router;