<template>
  <div class="container">
    <v-card class="group-main">
      <div class="title">
        <i class="iconfont icon-fenlei"></i>
        <span class="titleName">CS2相关资源网站</span>
      </div>
      <div
        style="
          display: flex;
          padding: 2px;
          margin-top: 3px;
          margin-left: 5px;
          margin-bottom: 3px;
        "
      >
        <a href="https://www.counter-strike.net/news" target="_blank">
          <button class="blue">CS2官网</button>
        </a>
        <span style="margin-left: 3px"></span>
        <a href="https://www.hltv.org/" target="_blank">
          <div style="height=500px"><button class="pink">HLTV</button></div>
        </a>
      </div>
      <div
        style="
          display: flex;
          padding: 2px;
          margin-left: 5px;
          margin-bottom: 3px;
        "
      >
         <a href="https://steamdb.info/app/730/" target="_blank">
        <div style="height=500px"><button class="purple">steamDB</button></div></a>
        <span style="margin-left: 3px"></span>
         <a href="https://www.rushb.net.cn/httpswww.rushb.net/default.htm" target="_blank">
        <div><button class="green">RushB中文网镜像</button></div></a>
      </div>
      <div
        style="
          display: flex;
          padding: 2px;
          margin-left: 5px;
          margin-bottom: 3px;
        "
      >
      
        <a href="https://cs2rankings.com/" target="_blank">
        <div style="height=500px"><button class="red">CS2全球排名</button></div></a>
        <span style="margin-left: 3px"></span>
              <a href="https://cases.jonesy.moe/" target="_blank">
        <div><button class="orange">开箱回本率</button></div></a>
      </div>

          <div
        style="
          display: flex;
          padding: 2px;
          margin-left: 5px;
          margin-bottom: 3px;
        "
      >
      
        <a href="https://convars.com/csgostats/zh/ban" target="_blank">
        <div style="height=500px"><button class="seven">VAC封禁浪潮</button></div></a>
        <span style="margin-left: 3px"></span>
                 <a href="https://prosettings.net/games/cs-go/" target="_blank">
        <div><button class="eight">选手设置</button></div></a>
      </div>

      <div class="erweima">
        <!-- <span class="desc">点击菜单“关于”可赞助</span> -->
      </div>
    </v-card>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },

  methods: {
    CS2click: function () {},
    goToCasePage() {
      this.$router.push("/case");
    },
    isShow(type) {
      return this.$store.state.webSiteInfo.showList.indexOf(type) != -1;
    },
    // handleClike(val) {
    //     window.location.href = val
    // },
    handleClike(val) {
      this.$router.push({
        path: val,
        hash: window.location.hash.substr(1),
      });
    },
    handleCollect() {
      console.log("666", this.$store.state.webSiteInfo);
      this.$toast({ type: "success", message: "按CTRL+D 键将本页加入书签" });
    },
  },
  created() {
    //   console.log("666",$store.state.webSiteInfo.authorInfo)
  },
};
</script>

<style lang="scss" scoped>
#frozen-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

button {
  border: 0;
  //   margin: 20px;
  text-transform: uppercase;
  font-size: 15px;
  font-weight: bold;
  padding: 10px 10px;
  border-radius: 30px;
  color: white;
  outline: none;
  width: 140px;
  //   position: relative;
}


.eight {
  background-image: linear-gradient(to right, #ff1d1d, #f026df);
  box-shadow: 0 4px 15px 0 rgba(49, 196, 190, 0.75);
}

.seven {
  background-image: linear-gradient(to right,#f026ad, #ff1d1d);
  box-shadow: 0 4px 15px 0 rgba(250, 74, 121, 0.75);
}


.green {
  background-image: linear-gradient(to right, #0088ff, #2ef193cf);
  box-shadow: 0 4px 15px 0 rgba(49, 196, 190, 0.75);
      font-size: 13px;
}

.purple {
  background-image: linear-gradient(to right, #2ef193, #0088ff);
  box-shadow: 0 4px 15px 0 rgba(236, 116, 149, 0.75);
}

.orange {
  background-image: linear-gradient(to right, #ffa200, #9ff318);
  box-shadow: 0 4px 15px 0 rgba(236, 116, 149, 0.75);
}
.orange:hover:before {
  background-position: left bottom;
}

.red {
  background-image: linear-gradient(to right, #9ff318, #ffa200);
  box-shadow: 0 4px 15px 0 rgba(236, 116, 149, 0.75);
}
.red:hover:before {
  background-position: left bottom;
}
.pink {
  background-image: linear-gradient(to right, #ae00fff5, #2190f9e2);
  box-shadow: 0 4px 15px 0 rgba(236, 116, 149, 0.75);
}
.pink:hover:before {
  background-position: left bottom;
}
.blue {
  background-image: linear-gradient(to right, #2190f9e2, #ae00ffe2);
  box-shadow: 0 4px 15px 0 rgba(236, 116, 149, 0.75);
}
.blue:hover:before {
  background-position: left bottom;
}
.purple:hover:before {
  background-position: left bottom;
}

.green:hover:before {
  background-position: left bottom;
}
.container {
  padding: 0;

  .group-main {
    width: 300px;
    margin-top: 20px;
    border-radius: 10px;
    background-color: var(--background-color);

    &:hover {
      box-shadow: 0 4px 8px 6px rgba(7, 17, 27, 0.06);
      transition: all 0.3s;
    }

    .title {
      width: 300px;
      height: 45px;
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;
      border-bottom: 1px solid var(--border-line);

      i {
                color: var(--theme-color);
                font-size: 16px;
                line-height: 45px;
                padding-left: 20px;
                font-weight: 700;
            }

      .titleName {
                color: var(--box-title_color);
                line-height: 45px;
                font-size: 16px;
                padding-left: 10px;
                font-weight: 600;
            }

      i,
      span {
        line-height: 45px;
      }
    }

    .erweima {
      img {
        height: 220px;
        width: 250px;
        margin-left: 25px;
        padding: 10px;
      }

      .desc {
        color: #606266;
        font-size: 14px;
        padding-left: 20px;
        height: 30px;
        display: block;
      }
    }
  }
}
</style>