import Vue from 'vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import locale from 'element-ui/lib/locale/lang/zh-CN' 
import App from './App.vue'
import config from "./assets/js/config";
import './assets/font/iconfont.css'
import './assets/font/iconfont.js'
import InfiniteLoading from "vue-infinite-loading";
import store from './store'
import animated from 'animate.css'
import 'wowjs/css/libs/animate.css'
import wow from 'wowjs'
import VMdPreview from '@kangc/v-md-editor/lib/preview';
import '@kangc/v-md-editor/lib/style/preview.css';
import githubTheme from '@kangc/v-md-editor/lib/theme/github.js';
import '@kangc/v-md-editor/lib/theme/style/github.css';
import createLineNumbertPlugin from '@kangc/v-md-editor/lib/plugins/line-number/index';
import createCopyCodePlugin from '@kangc/v-md-editor/lib/plugins/copy-code/index';
import '@kangc/v-md-editor/lib/plugins/copy-code/copy-code.css';
import hljs from 'highlight.js';
import "../src/icons";
import Toast from "./components/toast/index";
import vuetify from '@/plugins/vuetify'
import { vueBaberrage } from 'vue-baberrage'
import { setSkin } from '@/utils/skin'
import jsCookie from 'js-cookie'
import mavonEditor from 'mavon-editor'
import 'mavon-editor/dist/css/index.css'
import * as echarts from 'echarts'
Vue.prototype.$echarts = echarts

// import ElementUI from 'element-ui';
// import 'element-ui/lib/theme-chalk/index.css';

import pagination from '@/components/pagination2/index.vue'
Vue.component("sy-pagination", pagination);


import VueImageSwipe from 'vue-image-swipe'
import 'vue-image-swipe/dist/vue-image-swipe.css'
Vue.use(VueImageSwipe);
Vue.use(mavonEditor)
Vue.use(ElementUI);

// new Vue({
//   el: '#app',
//   render: h => h(App)
// });

Vue.prototype.$cookie = jsCookie;  // 在页面里可直接用 this.$cookie 调用
Vue.prototype.$setSkin = setSkin;

Vue.use(vueBaberrage)
VMdPreview.use(githubTheme, {
  Hljs: hljs,
});
VMdPreview.use(createLineNumbertPlugin())
VMdPreview.use(createCopyCodePlugin())
Vue.use(VMdPreview);
Vue.use(Toast);
Vue.prototype.$wow = wow
Vue.use(InfiniteLoading);

Vue.config.productionTip = false
Vue.prototype.config = config;
import router from './router'

new Vue({
  store,
  router,
  vuetify,
  render: h => h(App),
}).$mount('#app')

router.beforeEach((to, from, next) => {
  // if (to.path) {
  //  window._hmt.push(['_trackPageview', to.fullPath]);
  //   }
  // }
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})
router.afterEach(() => {
  window.scrollTo({
    top: 0,
    behavior: "instant"
  });
});


