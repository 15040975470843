import request from '@/utils/request'



export function getsiteList() {
    return request({
      url: 'v1/site/siteList',
      // url: '/system/article/case',
      method: 'get',
    })
  }
  

  
export function getRecommendedList() {
  return request({
    url: 'v1/site/getRecommendedList',
    // url: '/system/article/case',
    method: 'get',
  })
}