0   <template>
  <aside class="containner">
    <v-card class="box">
      <!-- <img src="https://www.ywsj.cf/themes/joe2.0/source/img/author_bg.jpg" alt=""> -->
      <div class="user">
        <!-- <div class="avatar_wrapper">
                    <img :src="$store.state.webSiteInfo.authorAvatar" alt="">
                </div> -->

        <!-- <a class="username">{{ $store.state.webSiteInfo.author }}</a> -->
        <!-- <div style="display:flex">
       <div class="username" style="cursor: pointer;" @click="goToCasePage()">距离上次大行动：</div>
        
          <div class="datestyle" style="cursor: pointer;" @click="goToCasePage()">{{ $store.state.webSiteInfo.datediff }}天</div>
        </div> -->
        

            <!-- <div style="display:flex">
        <div class="username" style="cursor: pointer;" @click="goToCasePage()">   距离上次武器箱：</div>
        
          <div class="datestyle" style="cursor: pointer;" @click="goToCasePage()"> {{ $store.state.webSiteInfo.casedatediff }}天</div>
        </div> -->
             <div style="display:flex">
        <div class="username" style="cursor: pointer;" @click="goToCasePage()">   游戏日志检测:</div>
        
          <div class="datestyle" style="cursor: pointer;" >{{monthplayer.noteTime}}</div>
        </div>
    <div class="count">
          <div
            class="item"
           @click="goToCasePage()"
            :title="$store.state.siteCount.articleCount"
          >
            <span class="num">           <span class="num">{{monthplayer.playerNow}}</span></span>
            <span class="itemName">当前在线人数</span>
          </div>
          <div
            class="item"
           @click="goToCasePage()"
            :title="$store.state.siteCount.categoryCount"
          >
            <span class="num">{{monthplayer.playerMonth}}</span>
            <span class="itemName">月活跃人数</span>
          </div>
      
        </div>
            <div class="count">
          <div
            class="item"
           @click="goToCasePage()"
            :title="$store.state.siteCount.articleCount"
          >
            <span class="num">{{ $store.state.webSiteInfo.casedatediff }}天</span>
            <span class="itemName">武器箱</span>
          </div>
          <div
            class="item"
          @click="goToCasePage()"
            :title="$store.state.siteCount.categoryCount"
          >
            <span class="num">{{ $store.state.webSiteInfo.datediff }}天</span>
            <span class="itemName">大行动</span>
          </div>
      
        </div>
        

<!-- <el-button type="text"  @click="goToCasePage()" >点此查看武器箱大行动历史信息</el-button> -->
        <!-- <el-button  style="color:#fb6c28"  @click="goToCasePage"    > 点此查看武器箱大行动历史信息</el-button> -->
  <!-- <div class="username">    <router-link :to="{ path: '/case' }"> 点击查看武器箱大行动更多信息 </router-link> </div> -->
        
    
     

        <!-- <a class="username">
          </a
        > -->
        <!-- <span class="desc" :title="$store.state.webSiteInfo.datediff">
                
                </span> -->
        <!-- <div class="count">
          <div
            class="item"
            @click="handleClike('/archive')"
            :title="$store.state.siteCount.articleCount"
          >
            <span class="num">{{ $store.state.siteCount.articleCount }}</span>
            <span class="itemName">文章数</span>
          </div>
          <div
            class="item"
            @click="handleClike('/categorys')"
            :title="$store.state.siteCount.categoryCount"
          >
            <span class="num">{{ $store.state.siteCount.categoryCount }}</span>
            <span class="itemName">分类数</span>
          </div>
          <div
            class="item"
            @click="handleClike('/tag')"
            :title="$store.state.siteCount.tagCount"
          >
            <span class="num">{{ $store.state.siteCount.tagCount }}</span>
            <span class="itemName">标签数</span>
          </div>
        </div> -->
    
        
  
      </div>
    </v-card>
  </aside>
</template>
<script>
import { getMonthlyPlayerCount} from "@/api/index";
export default {
  data() {
    return {
        monthplayer: '',
    };
  },

  methods: {
     getMonthlyPlayer() {
      getMonthlyPlayerCount()
        .then((res) => {
          this.monthplayer = res.data;

        })
        .catch((err) => {
          console.log(err);
        });
    },
   goToCasePage() {
      this.$router.push('/case')
    },
    isShow(type) {
      return this.$store.state.webSiteInfo.showList.indexOf(type) != -1;
    },
    // handleClike(val) {
    //     window.location.href = val
    // },
    handleClike(val) {
      this.$router.push({
        path: val,
        hash: window.location.hash.substr(1),
      });
    },
    handleCollect() {
      console.log("666", this.$store.state.webSiteInfo);
      this.$toast({ type: "success", message: "按CTRL+D 键将本页加入书签" });
    },
  },
  created() {
       this.getMonthlyPlayer();
    //   console.log("666",$store.state.webSiteInfo.authorInfo)
  },
};
</script>
<style lang="scss" scoped>
.v-sheet.v-card:not(.v-sheet--outlined) {
    box-shadow: none;
}
.containner {
  padding: 0;
  margin-top: 80px;

  .box {
    width: 100%;
    height: auto;
    background-color: var(--background-color);
    display: block;
    // border-radius: 10px;
    position: relative;


    &:hover {
      box-shadow: 5px 4px 8px 6px rgba(7, 17, 27, 0.06);
      transition: all 0.3s;
      transition: all 0.3s;
    }

    &::before {
      content: "";
      position: absolute;
      top: 90px;
      left: 0;
      width: 100%;
    //   height: 30px;
      z-index: 2;
      background: linear-gradient(to bottom, rgba(255, 255, 255, 0), #fff);
    }

    img {
      width: 100%;
      height: 120px;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      object-fit: cover;
      position: absolute;
      top: 0;
    }

    .user {
      
      padding: 9px 10px 0px;
      display: flex;
      align-items: center;
      position: relative;
      flex-direction: column;

      .avatar_wrapper {
        position: relative;
        width: 75px;
        height: 75px;
        margin-bottom: 12px;

        img {
          width: 75px;
          height: 75px;
          border-radius: 50%;
          display: block;
          overflow: hidden;
          padding: 5px;
          -o-object-fit: cover;
          object-fit: cover;
          transition: transform 0.5s;

          &:hover {
            transform: rotate(360deg);
          }
        }
      }

      // .username {
      //     color: var(--theme-color);
      //     display: block;
      //     font-size: 19px;
      //     font-weight: 500;
      //     margin-bottom: 10px;
      // }

      .username {
        color: #f01515;
        display: block;
        font-size: 19px;
         font-weight: 550;
    //   margin-left: 40px;
      margin-bottom: 6px;
      }
 

      .datestyle {
        color: #1474ff;
        display: block;
        font-size: 19px;
        font-weight: 550;
       
      }
      .desc {
        font-size: 14px;
        color: var(--text-color);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
        text-align: center;
      }

      .count {
        width: 100%;
        padding-bottom: 10px;
        display: flex;
        align-items: center;
        padding-bottom: 10px;
        -webkit-box-align: center;
        // margin-top: 20px;

        .item {
          position: relative;
          min-width: 0;
          -webkit-box-flex: 1;
          -ms-flex: 1;
          flex: 1;
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
          -ms-flex-direction: column;
          flex-direction: column;
          font-size: 12px;
          display: flex;
          border-right: 1px solid var(--border-line);
          cursor: pointer;

          &:last-child {
            border-right: 0;
          }

          .num {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font-weight: 500;
            font-size: 22px;
            margin-bottom: 3px;
            text-align: center;
            color: var(--site-color);
          }

          .itemName {
            font-size: 12px;
            text-align: center;
            color: var(--text-color);
          }
        }
      }

      .collect {
        margin-top: 8px;
        width: 100%;

        .btn {
          background-color: transparent;
          border-radius: 5px;
          border: 2px solid var(--theme-color);
          color: var(--theme-color);
          padding: 10px 20px;
          font-weight: 700;
          position: relative;
          transition: all 1s;
          z-index: 1;
          overflow: hidden;
          height: 30px;
          width: 100%;
          line-height: 8px;

          &:hover {
            color: white;

            &::before {
              width: 180%;
            }
          }

          &::before {
            content: "";
            height: 100%;
            position: absolute;
            left: -30px;
            top: 0;
            background-color: var(--theme-color);
            transform: skewX(45deg);
            width: 0%;
            transition: all 1s;
            z-index: -1;
          }
        }
      }

      .lianxi {
        border-top: 1px solid var(--border-line);
        justify-content: space-around;
        padding-top: 10px;
        text-align: center;
        align-items: center;
        font-size: 14px;
        display: flex;
        margin-top: 6px;

        a {
          display: inline-block;
          margin: 0 10px;
          transition: all 0.5s;

          &:hover {
            transform: scale(1.2);
          }

          svg {
            width: 18px;
            height: 18px;
          }
        }
      }
    }
  }
}

@keyframes img {
  0% {
    -webkit-transform: rotate(0deg);
  }

  50% {
    -webkit-transform: rotate(180deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}
</style>