<template>
    <div class="artcile_main">

 <div class="about-box">
    <div class="box1">
      <div class="title2">
        <span>热门网站</span>
      </div>
      <el-row
        :gutter="15"
        class="site-cards"
        style="margin-left: 0px; margin-right: 0px"
      >
        <el-col
          :xs="6"
          :sm="6"
          :md="3"
          :lg="3"
          :xl="3"
          v-for="site in recommendedData"
          :key="site.id"
          class="site-card"
        >
          <div class="site-card-content">
            <div class="site-image">
              <a
                :href="site.url"
                target="_blank"
                style="text-decoration: none; color: inherit"
              >
                <img :src="site.thumb" class="site-image-square"
              /></a>
            </div>
            <div class="site-info">
              <a
                :href="site.url"
                target="_blank"
                :class="'item3'"
                style="text-decoration: none; color: inherit;white-space: nowrap;"
                class="site-title"
              >
                <strong>{{ site.title }}</strong></a
              >
              <!-- <p class="site-title">hltv</p> -->
            </div>
          </div>
        </el-col>
      </el-row>


    </div>
  </div>
  

        <div class="title">
            <span>最新文章</span>
        </div>
        <div class="mainBox">
            <ul class="ul_item" v-for="item in articleList">
                <li class="main_li wow bounceIn" @click="handleClick(item.id)" data-wow-duration="2s"
                    data-wow-iteration="1">
                    <div href="" class="thumbnail">
                        <img class="article_cover" :src="item.avatar" :alt="item.title" @error="item.avatar = img" />
                        <i class="iconfont icon-tupian"></i>
                        <span class="time">{{ item.createTime }}</span>
                    </div>
                    <div class="information">
                        <div class="titleName">
                            <span v-if="item.isStick == 1" class="top">置顶</span>
                            <span v-if="item.isOriginal == 1" class="original">原创</span>
                            {{ item.title }}
                        </div>
                        <div class="info">
                            {{ item.summary }}
                        </div>
                        <div class="meta">
                            <ul class="items">
                                <li>{{ item.username }}</li>
                                <li>{{ item.createTime }}</li>
                                <!-- <li><i class="iconfont icon-yuedu"></i>{{ item.quantity }}</li> -->
                                <!-- <li><i class="iconfont icon-pinglun"></i>{{ item.commentCount }}</li> -->
                                <!-- <li><i class="iconfont icon-dianzan"></i>{{ item.likeCount }}</li> -->
                            </ul>
                            <ul class="items">
                                <li v-for="tag in item.tagList">
                                    <i class="iconfont icon-biaoqian"></i>
                                    <span class="tag_name" @click="">{{ tag.name }}</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </li>

            </ul>
        </div>
    </div>
</template>
<script>
import { getRecommendedList } from "@/api/site";
export default {
    name: 'articleList',
    props: {
        articleList: {
            type: Array,
            default: () => [],
        }
    },
    data() {
        return {
               recommendedData: [],
            img: "http://img.shiyit.com/error.jpg"
        }
    },
    mounted() {
        new this.$wow.WOW().init()
    },
      created() {
    document.title = "导航";
 
    this.getRecommended();
  },
    methods: {
            getRecommended() {
      getRecommendedList()
        .then((res) => {
          this.recommendedData = res.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
        formatDate: function (value, args) {
            var dt = new Date(value);
            let year = dt.getFullYear();
            let month = (dt.getMonth() + 1).toString().padStart(2, '0');
            let date = dt.getDate().toString().padStart(2, '0');
            return `${year}-${month}-${date}`;
        },
        handleClick(id) {
            this.$router.push({ path: '/articleInfo', query: { articleId: id } })
        },

    }
}
</script>
<style lang="scss" scoped>

.el-card.is-always-shadow {
  box-shadow: 0px 0px 20px -5px rgba(158, 158, 158, 0.27);
}
.site-cards {
  margin-top: -10px;
  // border: 1px solid #ebeef5; /* 设置边框的粗细和颜色 */
  padding: 2px; /* 设置边框内部的内边距 */
  //  border-radius: 10px;
}

.site-card {
  margin-top: 15px;
}

.site-card-content {
  border-radius: 10px;
  background: linear-gradient(to bottom, var(--background-color), #ebeef5);
  padding: 2px 0px 5px 0px;
  // box-shadow: 0px 0px 20px -5px rgba(158,158,158,0.27)
}

.site-image {
  text-align: center;
}

.site-image-square {
  width: 55px;
  height: 55px;
  border-radius: 10px;
}

.site-info {
  text-align: center;
}

.site-title {
  font-size: 16px;
  font-weight: bold;
  margin-top: 0px;
}
.about-box .box1 {
  .title1 {
    width: 100%;
    height: 45px;
    padding-left: 10px;
    color: var(--theme-color);
    position: relative;
    // border-bottom: 1px solid var(--border-line);

    span {
      display: inline-block;
      font-size: 14px;
      line-height: 45px;
      font-weight: 700;
    }
  }
  .title2 {
    width: 100%;
    height: 45px;
    padding-left: 10px;
    padding-bottom: -11px;
    color: var(--theme-color);
    position: relative;
    // border-bottom: 1px solid var(--border-line);

    span {
      display: inline-block;
      font-size: 14px;
      line-height: 45px;
      font-weight: 700;
    }
  }
}
.ellipsis-text {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* 将行数改为1 */
  -webkit-box-orient: vertical;
}
.el-card ::v-deep .el-card__header {
  padding: 4px 10px 4px 10px;
  border-bottom: 1px solid #ebeef5;
  box-sizing: border-box;
}
.el-card ::v-deep .el-card__body {
  padding-top: 5px;
  padding-bottom: 10px;
  height: 54px;
  // padding-left: 15px;
  // padding-right: 8px
}
.custom-table .el-table__row {
  height: 200px !important; /* 调整为所需的行高 */
  line-height: 200px !important; /* 与 height 值保持一致，用于垂直居中内容 */
}
.el-card {
  border: none;
  background-color: #fff;
}
@media screen and (max-width: 1118px) {
    .artcile_main {
        background-color: var(--background-color);
        border-bottom-left-radius: 12px;
        border-bottom-right-radius: 12px;

        .title {
            width: 100%;
            height: 45px;
            padding-left: 10px;
            color: var(--theme-color);
            position: relative;
            border-bottom: 1px solid var(--border-line);

            span {
                display: inline-block;
                font-size: 14px;
                line-height: 45px;
                font-weight: 700;
            }
        }

        .mainBox {
            width: 100%;

            .ul_item {
                width: 100%;
                padding-left: 5px;
                cursor: pointer;
                border-bottom: 1px solid var(--border-line);

                &:last-child {
                    border: 0;
                }

                .main_li {
                    list-style: none;
                    display: flex;
                    position: relative;
                    padding-bottom: 18px;
                    margin-top: 18px;
                    width: 100%;


                    .thumbnail {
                        flex-shrink: 0;
                        position: relative;
                        width: 150px;
                        height: 120px;
                        margin-right: 15px;
                        overflow: hidden;
                        text-decoration: none;

                        .article_cover {
                            border-radius: 4px;
                            object-fit: cover;
                            width: 100%;
                            height: 100%;
                        }

                        .iconfont {
                            position: absolute;
                            top: 2px;
                            left: 5px;
                            color: var(--background-color);
                            font-size: 18px;
                        }

                        .time {
                            display: none;
                        }
                    }

                    .information {
                        display: flex;
                        -webkit-box-orient: vertical;
                        -webkit-box-direction: normal;
                        -ms-flex-direction: column;
                        flex-direction: column;
                        -webkit-box-flex: 1;
                        -ms-flex: 1;
                        flex: 1;
                        min-width: 0;
                        width: 100%;

                        .titleName {
                            margin-bottom: 10px;
                            color: var(--article-color);
                            font-size: 18px;
                            line-height: 24px;
                            max-height: 48px;
                            -webkit-transition: color .35s;
                            transition: color .35s;
                            text-decoration: none;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            width: 100%;

                            .top,
                            .original {
                                display: none;
                            }
                        }

                        .info {
                            color: var(--text-color);
                            line-height: 22px;
                            max-height: 44px;
                            opacity: .85;
                            text-decoration: none;
                            width: 100%;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                        }

                        .meta {
                            -webkit-box-pack: justify;
                            -ms-flex-pack: justify;
                            justify-content: space-between;
                            margin-top: auto;
                            color: var(--text-color);
                            font-size: 13px;
                            -webkit-box-align: center;
                            -ms-flex-align: center;
                            align-items: center;
                            width: 100%;

                            .items {
                                -webkit-box-pack: start;
                                -ms-flex-pack: start;
                                justify-content: flex-start;
                                -ms-flex-negative: 0;
                                flex-shrink: 0;
                                -webkit-box-align: center;
                                -ms-flex-align: center;
                                align-items: center;
                                padding: 0 0;
                                width: 100%;

                                li {
                                    list-style: none;
                                    display: inline-block;

                                    &:after {
                                        content: "/";
                                        color: #c0c4cc;
                                        padding: 0 5px;
                                    }

                                    &:last-child:after {
                                        content: "";

                                    }

                                    i {
                                        padding: 0 3px;
                                        font-size: 13px;
                                    }

                                    .tag_name {
                                        color: var(--text-color);
                                        text-decoration: none;
                                        width: 100%;

                                        &:hover {
                                            cursor: pointer;
                                            color: var(--theme-color);
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }


    }
}

@media screen and (min-width: 1119px) {
    .artcile_main {
        background-color: var(--background-color);
        border-bottom-left-radius: 12px;
        border-bottom-right-radius: 12px;

        .title {
            width: 100%;
            height: 45px;
            color: var(--theme-color);
            position: relative;
            border-bottom: 1px solid var(--border-line);
            margin-left: 15px;

            span {
                display: inline-block;
                font-size: 14px;
                line-height: 45px;
                font-weight: 700;
            }
        }

        .ul_item {
            padding-left: 20px;
            cursor: pointer;
            border-bottom: 1px solid var(--border-line);

            &:last-child {
                border: 0;
            }

            &:hover {
                .article_cover {
                    transform: scale(1.2);
                }

                .time {
                    transform: translateX(-110%) translateY(5%);
                    transition: all 0.5s;
                }

                .main_li .information .titleName,
                .main_li .information .info {
                    color: var(--theme-color);
                }

                .main_li::before {
                    transform: scaleX(1);
                }

            }

            .main_li {
                list-style: none;

                display: flex;
                position: relative;
                padding-bottom: 18px;
                margin-top: 18px;



                &::before {
                    content: "";
                    width: 4px;
                    height: 30px;
                    background-color: var(--theme-color);
                    position: absolute;
                    left: -15px;
                    border-radius: 50px;
                    transform: scaleX(0);
                    transition: all 0.5s;
                }

                .thumbnail {
                    flex-shrink: 0;
                    position: relative;
                    width: 210px;
                    height: 140px;
                    margin-right: 15px;
                    overflow: hidden;
                    text-decoration: none;

                    .article_cover {
                        border-radius: 4px;
                        object-fit: cover;
                        width: 100%;
                        height: 100%;
                        transition: all 0.3s;
                    }

                    .iconfont {
                        position: absolute;
                        top: 2px;
                        left: 5px;
                        color: var(--background-color);
                        font-size: 18px;
                    }

                    .time {
                        display: block;
                        padding: 5px 5px;
                        border-radius: 20px;
                        position: absolute;
                        font-size: 12px;
                        top: 3px;
                        right: -38%;
                        background-color: var(--theme-color);
                        color: var(--background-color);
                    }
                }

                .information {
                    display: flex;
                    -webkit-box-orient: vertical;
                    -webkit-box-direction: normal;
                    -ms-flex-direction: column;
                    flex-direction: column;
                    -webkit-box-flex: 1;
                    -ms-flex: 1;
                    flex: 1;
                    min-width: 0;

                    .titleName {
                        margin-bottom: 10px;
                        color: var(--article-color);
                        font-size: 18px;
                        line-height: 24px;
                        max-height: 48px;
                        -webkit-transition: color .35s;
                        transition: color .35s;
                        text-decoration: none;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        width: 100%;

                        .top,
                        .original {
                            height: 20px;
                            padding: 0 6px;
                            margin-right: 5px;
                            line-height: 20px;
                            font-size: 12px;
                            white-space: nowrap;
                            vertical-align: 2px;
                            color: var(--background-color);

                            border-radius: 2px 6px;
                            display: inline-block;
                        }

                        .top {
                            background-image: -webkit-linear-gradient(0deg, #3ca5f6 0, #a86af9 100%);
                        }

                        .original {
                            background-image: -webkit-linear-gradient(0deg, #26A69A 0, #00897B 100%);
                        }
                    }

                    .info {
                        color: var(--text-color);
                        line-height: 22px;
                        max-height: 44px;
                        opacity: .85;
                        text-decoration: none;
                        width: 100%;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }

                    .meta {
                        -webkit-box-pack: justify;
                        -ms-flex-pack: justify;
                        justify-content: space-between;
                        margin-top: auto;
                        color: var(--text-color);
                        font-size: 13px;
                        display: flex;
                        -webkit-box-align: center;
                        -ms-flex-align: center;
                        align-items: center;

                        .items {
                            -webkit-box-pack: start;
                            -ms-flex-pack: start;
                            justify-content: flex-start;
                            -ms-flex-negative: 0;
                            flex-shrink: 0;
                            display: flex;
                            -webkit-box-align: center;
                            -ms-flex-align: center;
                            align-items: center;
                            padding: 0 0;

                            li {
                                list-style: none;
                                display: inline-block;

                                &:after {
                                    content: "/";
                                    color: #c0c4cc;
                                    padding: 0 5px;
                                }

                                &:last-child:after {
                                    content: "";

                                }

                                i {
                                    padding: 0 3px;
                                    font-size: 13px;
                                }

                                .tag_name {
                                    color: var(--text-color);
                                    text-decoration: none;

                                    &:hover {
                                        cursor: pointer;
                                        color: var(--theme-color);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

    }
}
</style>